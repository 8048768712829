import React from 'react';
import PropTypes from 'prop-types';

import styled from 'styled-components';

const ContainerBackground = styled.div`
  width: 100%;
  background-color: ${props => (props.backgroundCol ? props.backgroundCol : 'transparent')};
`;

const Container = styled.div`
  margin: ${props => (props.margin ? props.margin : '0 auto')};
  max-Width: 960px;
  padding: 100px 1.0875rem 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;


const pageContentContainer = ({
  children,
  backgroundCol,
  margin,
}) => (
  <ContainerBackground backgroundCol={backgroundCol}>
    <Container margin={margin}>
      {children}
    </Container>
  </ContainerBackground>
);

pageContentContainer.propTypes = {
  children: PropTypes.node.isRequired,
};

export default pageContentContainer;
